import brazeSdk from "frontend/braze-sdk";
import mixpanel from "mixpanel-browser";
import { PostHog } from "posthog-js/react";
import { User } from "shared/datamodel/schemas";
import { flattenObjectRecursive } from "shared/util/utils";

export function setUpAnalyticTools(user: User) {
  if (typeof window === "undefined") return;

  mixpanel.identify(user.id.toString());
  mixpanel.register(user);
  mixpanel.people.set(user);

  brazeSdk.setBrazeUser(user.id.toString(), prepareUserObjectForPosthog(user));
  brazeSdk.getInitializedBrazeClient()?.openSession();
  setUserOptedSettings(user);
}

export const posthogInit = (posthogRef: PostHog, user: User, version: string) => {
  if (typeof window === "undefined") return;

  posthogRef?.identify(user.id.toString(), prepareUserObjectForPosthog(user));
  posthogRef?.group("account", `id::${user?.accountId || user.account?.id || ""}`, {});
  posthogRef?.group("version", `version::${version}`, {});

  setFirstTimeUserInitialProperties(user, posthogRef);
  syncFeatureFlags(posthogRef);
};

function getSafeUrlPathname(url: string) {
  try {
    return new URL(url).pathname;
  } catch {
    return url;
  }
}

function setFirstTimeUserInitialProperties(user: User, posthogRef: PostHog) {
  const storedPersonProps = posthogRef.get_property("$stored_person_properties");

  if (!storedPersonProps?.$initial_pathname) {
    const initialPersionInfo = posthogRef.get_property("$initial_person_info")?.u || window.location.hostname;
    const propertiesForFeatureFlags = {
      ...user,
      $initial_pathname: getSafeUrlPathname(initialPersionInfo),
    };

    posthogRef.setPersonPropertiesForFlags(propertiesForFeatureFlags);
  }
}

function syncFeatureFlags(posthogRef: PostHog) {
  posthogRef.onFeatureFlags((_flags, variants) => {
    Object.entries(variants).forEach(([key, value]) => {
      if (key.includes("ab-test")) {
        mixpanel.register({ [key]: value });
        mixpanel.people.set(key, value);
      }
    });
  });
}

/**
 * Sets the user's opted-in or opted-out tracking settings based on specific conditions.
 *
 * The function determines whether the user should be opted out of tracking/.
 *
 * If the user is currently opted out but should not be, the function opts them back in.
 * If the user should be opted out, the function opts them out of tracking.
 *
 * @param {User} user - The user object containing account information.
 */
function setUserOptedSettings(user: User) {
  const shouldOptOutUser =
    user.account?.id === "5" ||
    process.env.NEXT_ENV !== "production" ||
    (window as any)._DATADOG_SYNTHETICS_BROWSER !== undefined;

  const isOptedOutUser = mixpanel.has_opted_out_tracking();

  if (isOptedOutUser && !shouldOptOutUser) {
    mixpanel.opt_in_tracking();
  }

  if (shouldOptOutUser) {
    mixpanel.opt_out_tracking(); //don't send data for monday account users or in dev or for datadog bots
  }
}

/**
 * This function filters certain properties and flattens the user object to sync to posthog, because the filters on posthog struggle with nested objects
 * @param user The user object to sync to posthog
 * @returns A flattened object that includes the user object and the planInfo and account objects
 */
function prepareUserObjectForPosthog(user: User) {
  const filteredUserObject = structuredClone(user);
  delete filteredUserObject.repsToken;
  delete filteredUserObject.serialNumber;
  delete filteredUserObject.planInfo?.enc_features;
  return flattenObjectRecursive(filteredUserObject);
}
